<div class="grid h-full grid-rows-[auto_1fr_auto] overflow-hidden">
    <header>
        <app-header></app-header>
    </header>
    <div class="p-2 overflow-y-scroll no-scrollbar">
        <app-sidenav></app-sidenav>
        <main class="h-full">
            <router-outlet>
                
            </router-outlet>
        </main>
    </div>
    <footer class="xl:w-1/3 xl:bg-vuturiaRed xl:mx-auto xl:rounded-full xl:my-8">
        <app-footer></app-footer>
    </footer>
</div>
