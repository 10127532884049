<div class="flex gap-4 lg:gap-10" *ngIf="game">
    <div class="aspect-square self-center justify-center">
        <img class="rounded-md" [src]="game.map.iconPath" width="80" height="80" alt="Map Icon"/>
    </div>
    <div class="flex-1 grid grid-cols-3 font-bold items-baseline gap-1">
        <h1 [class.text-green-400]="game.userHasWon" [class.text-vuturiaRed]="!game.userHasWon" class="text-lg">{{game.userHasWon ? 'Win' : 'Defeat'}}</h1>
        <div class="text-center">{{game.type == "freeforall" ? '' : game.score}}</div>
        <div class="text-sm text-right uppercase">{{game.type}}</div>
        <div class="text-vuturiaPurple3 self-end">{{((game.kills) / (game.deaths)).toFixed(2)}} KD</div>
        <div></div>
        <div class="text-xs text-right self-end">{{game.playedAt | date}}</div>
        <div>{{game.kills}} / {{game.deaths}}</div>
        <div></div>
        <div class="text-xs text-right">{{game.playedAt | date: 'hh'}} Uhr</div>
    </div>
</div>