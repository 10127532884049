import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { UserProfile } from "../../domain/user/user-profile";
import { UserDto } from "../../domain/user/user.dto";
import { environment } from "../../../environment";
import { CollectionResponse } from "../../domain/user/user-searchresult";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UserClient{

    constructor(private http: HttpClient){

    }

    public getUser(id: string): Observable<UserProfile>{
        return this.http.get<UserProfile>(environment.vuturiaApi.baseUrl + '/api/user/' + id);
    }

    public getMe(): Observable<UserProfile>{
        return this.http.get<UserProfile>(environment.vuturiaApi.baseUrl + '/api/v2/user/me');
    }

    public search(searchTerm: string): Observable<UserDto[]> {
        return this.http.get<CollectionResponse<UserDto>>(environment.vuturiaApi.baseUrl + '/api/user?searchterm=' + searchTerm).pipe(map(res => res.data));
    }
    
    public getFriendsForUser(id: string) : Observable<UserDto[]>{
        return this.http.get<UserDto[]>(environment.vuturiaApi.baseUrl + '/api/v2/user/id/friends');
    }

    public getFriendsForMe() : Observable<UserDto[]>{
        return this.http.get<UserDto[]>(environment.vuturiaApi.baseUrl + '/api/v2/user/me/friends');
    }

    public addFriend(id: string) : Observable<UserDto[]>{
        return this.http.post<UserDto[]>(environment.vuturiaApi.baseUrl + '/api/v2/user/me/friends', {
            friendToAddId: id
        });
    }

}