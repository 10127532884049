import { createActionGroup, props } from "@ngrx/store";
import { UserProfile } from "../../domain/user/user-profile";
import { UserReadmodel } from "../../domain/user/user-readmodel";
import { UserSearchResult } from "../../domain/user/user-searchresult";
import { Friend } from "../../domain/user/friend";

export const profileActions = createActionGroup({
    source: 'profile Actions',
    events: {
        "Begin Load UserProfile": props<{id: string}>(),
        "Load UserProfile Success": props<{profile: UserReadmodel}>(),
        "Load UserProfile Failure": props<{error: any}>(),
        
        "Expand GameDetails": props<{gameId: string}>(),

        "Show Delete UserProfile Dialog": props,
        "Hide Delete UserProfile Dialog": props,
        "Start Delete UserProfile": props,
        "Delete UserProfile Success": props,
        "Delete UserProfile Error": props<{error: any}>()

    }
})

export const userSearchActions = createActionGroup({
    source: 'user-search',
    events: {
        "Begin user search": props<{searchTerm: string}>(),
        "User search success": props<{users: UserSearchResult[]}>(),
        "User search failure": props<{error: any}>(),
    }
})

export const friendActions = createActionGroup({
    source: 'friends',
    events: {
        "Begin load friends": props<{id: string}>(),
        "Load friends Success": props<{friends: Friend[]}>(),
        "Load friends Error": props<{error: any}>(),
        "Add Friend": props<{friendId: string}>(),
        "Add friend success": props
    }
})