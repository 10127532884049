import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { Browser } from "@capacitor/browser";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { switchMap, of, map, catchError, tap, exhaustMap, EMPTY, filter, firstValueFrom, distinctUntilChanged } from "rxjs";
import { UserProfile } from "../../domain/user/user-profile";
import { authActions, registrationActions } from "./authentication.actions";
import { environment } from "../../../environment";
import OneSignal from "onesignal-cordova-plugin";
import { playActions } from "../play/play.actions";
import { Store } from "@ngrx/store";

@Injectable()
export class AuthenticationEffects{

    login$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.beginLogin),
        tap(async () => await firstValueFrom(this.auth.loginWithRedirect({
            async openUrl(url: string) {
              await Browser.open({ url, windowName: '_self' });
            }
          })))
    ), {dispatch: false});

    logout$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.beginLogout),
        switchMap(() => this.auth.logout({
            openUrl: false
        }).pipe(
            map(res => authActions.logoutSuccess())
        ))
    ));

    logutSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.logoutSuccess),
        tap(_ => this.router.navigate(['/']))
    ), {dispatch: false});

    loadLoginState$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.loadLoginState),
        switchMap(_ => this.auth.user$),
        filter(user => !!user),
        switchMap(user => of(authActions.loginSuccess({user: user!}))),
    ));

    loadProfile$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.loginSuccess, authActions.beginLoadUserProfile),
        switchMap(() => this.http.get<UserProfile>(environment.vuturiaApi.baseUrl + '/api/v2/user/me').pipe(
            map(res => authActions.loadUserProfileSuccess({profile: res})),
            catchError(error => of(authActions.loadUserProfileFailed({error})))))
    ));

    loadProfileSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.loadUserProfileSuccess),
        switchMap(_ => [playActions.loadCurrentDevice()])
    ));

    loadUserProfileFailed$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.loadUserProfileFailed),
        filter(action => action.error.status === 404),
        tap(async () => await this.router.navigate(['login', 'registration'])),
        exhaustMap(() => EMPTY)
    ), { dispatch: false });

    registration$ = createEffect(() => this.actions$.pipe(
        ofType(registrationActions.beginRegistration),
        switchMap(({body}) => this.http.post<UserProfile>(environment.vuturiaApi.baseUrl + "/api/v2/user/registration", body).pipe(
            map(resp => registrationActions.registrationSuccess({profile: resp})),
            catchError(err => of(registrationActions.registrationError({error: err})))
        ))
    ));

    registeredNavigateHome$ = createEffect(() => this.actions$.pipe(
        ofType(registrationActions.registrationSuccess),
        tap(action => this.router.navigate(['profile', action.profile.id])),
        map(() => EMPTY)
    ), {dispatch: false});

    setOneSignalUserOnLoginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(authActions.loadUserProfileSuccess),
        tap(action => {
            /// @ts-ignore
            if(window.cordova){
                OneSignal.login(action.profile.id);
                OneSignal.User.addAlias("username", action.profile.name);
            }
        })
    ), {dispatch: false})

    constructor(private actions$: Actions, private router: Router, private auth: AuthService, private http: HttpClient, private store$: Store){

        auth.user$.pipe(distinctUntilChanged()).subscribe(user => {
            if(user)
                store$.dispatch(authActions.loginSuccess({user}));
        });

    }
}