import { createAction, createActionGroup, props } from "@ngrx/store";
import { Entry } from "contentful";
import { TypeVuturia__newsSkeleton } from "../../data/news/contentful-news";


export const NewsActions = createActionGroup({
    source: 'news',
    events: {
        'begin load news': props,
        'load news finished': props<{news: ReadonlyArray<Entry<TypeVuturia__newsSkeleton>>}>(),
        'load news failed': props<{error: any}>(),
        'detail loaded': props<{slug: string}>()
    }
})