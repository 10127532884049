import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeModule, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-user-card-scanner',
  standalone: true,
  imports: [
    CommonModule, NgxScannerQrcodeModule
  ],
  templateUrl: './user-card-scanner.component.html',
  styleUrl: './user-card-scanner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardScannerComponent implements AfterViewInit, OnDestroy {

  @ViewChild('action') scanner!: NgxScannerQrcodeComponent;

  @Output() userFound: EventEmitter<any> = new EventEmitter<any>();
  @Output() userIdFound: EventEmitter<string> = new EventEmitter<string>();

  public config: ScannerQRCodeConfig = {
    isBeep: false,
    constraints: {
      video: {
        facingMode: {
          ideal: "environment"
        },
      }
    },
  }

  constructor(private http: HttpClient){}

  ngAfterViewInit(): void {
    this.scanner.start();
  }
  
  ngOnDestroy(): void {
    this.scanner.stop();
  }

  barcodeFound(results: ScannerQRCodeResult[]) {

    console.log(results);
    if(!results[0])
      return;

    this.scanner.pause();
    const url = results[0]?.value;

    //validate we have an url
    if(!url.startsWith('http')){
      this.scanner.play();
      return;
    }

    //validate we can extract a player
    const playerId = url.split('/').at(-1);
    if(!playerId?.indexOf('-')){
      this.scanner.play();
      return;
    }

    this.http.get(window.location.origin + '/api/user/' + playerId).subscribe(user => {
      this.userFound.next(user);
      this.userIdFound.next(playerId);
    }, _ => {
      this.scanner.play()
    });
  }

}
