<div class="flex flex-col items-stretch justify-center border border-vuturiaGray1 rounded bg-black">
    <div class="bg-vuturiaGray1 p-2">
        <h1 class="text-2xl text-center" translate="deleteDialog.title"></h1>
    </div>
    <div class="p-4">
        <h2 class="text-xl text-center" translate="deleteDialog.content.title"></h2>
        <p translate="deleteDialog.content.body"></p>
    </div>

    <div class="flex justify-evenly p-1 border-t border-vuturiaGray1">
        <button (click)="hide()" class="p-2 bg-vuturiaGray3 rounded-sm shadow-sm" translate="deleteDialog.buttons.cancel"></button>
        <button (click)="deleteUser()" class="p-2 bg-vuturiaPurple3 rounded-sm shadow-sm" translate="deleteDialog.buttons.delete"></button>
    </div>
    
</div>
