import { User } from "@auth0/auth0-angular";
import { UserProfile } from "../../domain/user/user-profile";
import { createReducer, on } from "@ngrx/store";
import { authActions, registrationActions } from "./authentication.actions";

export interface AuthState {
    account: User | undefined,
    signedInUserProfile: UserProfile | undefined,
    accountLoading: boolean,
    userProfileLoading: boolean,
    loading: boolean,
    error: string | undefined
}

const initialState: AuthState = {
    account: undefined,
    signedInUserProfile: undefined,
    accountLoading: false,
    userProfileLoading: false,
    loading: false,
    error: undefined
}

export const authReducer = createReducer(initialState, 
    on(authActions.beginLogin, state => ({...state, loading: true})),
    on(authActions.loginSuccess, (state, {user}) => ({...state, loading: false, account: user})),
    on(authActions.loginFailed, (state, {error}) => ({...state, loading: false, error: error})),
    on(authActions.beginLogout, state => ({...state, signedInUserProfile: undefined, account: undefined})),
    on(authActions.beginLoadUserProfile, (state) => ({...state, userProfileLoading: true})),
    on(authActions.loadUserProfileSuccess, (state, {profile}) => ({...state, userProfileLoading: false, signedInUserProfile: profile})),
    on(authActions.loadUserProfileFailed, (state, {error}) => ({...state, userProfileLoading: false, error: error.message})),
    on(registrationActions.beginRegistration, (state) => ({...state, loading: true})),
    on(registrationActions.registrationSuccess, (state, {profile}) => ({...state, loading: false, signedInUserProfile: profile})),
    on(registrationActions.registrationError, (state, {error}) => ({...state, loading: false, error: error})),

    on(authActions.setLoginState, (state, {user, profile}) => ({...state, account: user, signedInUserProfile: profile}))
)