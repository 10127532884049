import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, EMPTY } from "rxjs";
import { navigationActions } from "./navigation.actions";
import { Injectable } from "@angular/core";

@Injectable()
export class NavigationEffects{

    constructor(private actions$: Actions, private router: Router){

    }

    navigateToUser$ = createEffect(() => this.actions$.pipe(
        ofType(navigationActions.navigateToUser),
        switchMap(action => {
            this.router.navigate(['profile', action.id]);
            return EMPTY;
        })
    ), {dispatch: false});

    navigateHome$ = createEffect(() => this.actions$.pipe(
        ofType(navigationActions.navigateHome),
        switchMap(action => {
            this.router.navigate(['home']);
            return EMPTY;
        })
    ), {dispatch: false});


    navigateLogin$ = createEffect(() => this.actions$.pipe(
        ofType(navigationActions.navigateToLogin),
        switchMap(action => {
            this.router.navigate(['login']);
            return EMPTY;
        })
    ), {dispatch: false});
}