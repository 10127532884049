import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { AuthSelectors, selectUser } from '../../../state/authentication/authentication.selector';
import { UserRegistrationRequest, authActions, registrationActions } from '../../../state/authentication/authentication.actions';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements OnInit {

  form = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(12)]),
    mail: new FormControl('', [Validators.required, Validators.email]),
    agb: new FormControl(false, [Validators.requiredTrue]),
    marketingConsent: new FormControl(false)
  });

  userProfileLoading$ = this.store$.select(AuthSelectors.selectUserProfileLoading)

  spinnerIcon = faSpinner;

  constructor(private store$: Store){

  }

  ngOnInit(): void {
      this.store$.dispatch(authActions.beginLoadUserProfile());
      this.store$.select(selectUser).pipe(first()).subscribe(user => {
        this.form.setValue({
          username: user?.nickname ?? '',
          mail: user?.email ?? '',
          agb: false,
          marketingConsent: false
        })
      });
  }

  registerUser(){
    const formValue = this.form.value;
    const body: UserRegistrationRequest = {
      username: formValue.username ?? '',
      mail: formValue.mail ?? '',
      agb: formValue.agb ?? false,
      marketingConsent: formValue.marketingConsent ?? false
    }
    this.store$.dispatch(registrationActions.beginRegistration({body}))
  }

}
