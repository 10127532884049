import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, importProvidersFrom } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap, take, tap } from 'rxjs';
import { selectCurrentDetailNews } from '../../../state/news/news.selectors';
import { ToHtmlPipe } from '../to-html.pipe';
import { News } from '../../../domain/news/news';
import { TypeVuturia__news } from '../../../data/news/contentful-news';

@Component({
  selector: 'app-news-detail',
  standalone: true,
  imports: [
    CommonModule, ToHtmlPipe
  ],
  templateUrl: './news-detail.component.html',
  styleUrl: './news-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsDetailComponent {

  news$ = this.store.select(selectCurrentDetailNews);

  constructor(private store: Store){
  }

  generateImageUrl(news: any) {
    return "https://" + news.fields.header.fields.file.url +"?fm=jpg&fl=progressive&q=50&w=1000";
  }

}
