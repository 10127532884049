import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectExpandedGameId, selectGameHistory, selectProfile } from '../../../state/user/user.selector';
import { GameCardComponent } from './components/game-card/game-card.component';

@Component({
  selector: 'app-game-history',
  standalone: true,
  imports: [
    CommonModule, GameCardComponent
  ],
  templateUrl: './game-history.component.html',
  styleUrl: './game-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameHistoryComponent {


  gameHistory$ = this.store.select(selectGameHistory);

  constructor(private store: Store){}

}
