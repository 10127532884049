import { createReducer, on } from "@ngrx/store";
import { Device } from "../../domain/device";
import { playActions } from "./play.actions";

export interface PlayState{
    isLoading: boolean,
    currentDevice?: Device
}

export const initialState: PlayState = {
    isLoading: false,
    currentDevice: undefined
};

export const playReducer = createReducer(
    initialState,
    on(playActions.playOnDevice, (state, action) => ({...state, isLoading: true})),
    on(playActions.loadCurrentDevice, state => ({...state, isLoading: true})),
    on(playActions.setCurrentDevice, (state, action) => ({...state, currentDevice: action.device, isLoading: false})),
    on(playActions.stopPlaying, state => ({...state, currentDevice: undefined}))
)