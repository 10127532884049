import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNavbarExpanded } from '../../state/layout/layout.selectors';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LanguageActions } from '../../state/language/language.actions';
import { navbarActions } from '../../state/layout/layout.actions';
import { authActions } from '../../state/authentication/authentication.actions';
import { selectUser } from '../../state/authentication/authentication.selector';
import { map } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule, RouterModule
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations:[
    trigger('openClose', [
      state('nav-open', style({
        left: 0
      })),
      state('nav-closed', style({
        left: '-100%'
      })),
      transition('nav-open <=> nav-closed', [
        animate(100)
      ])
    ])
  ]
})
export class SidenavComponent {


  navbarExpanded$ = this.store$.select(selectNavbarExpanded);
  isLoggedIn$ = this.store$.select(selectUser).pipe(map(u => !!u));
  
  constructor(private store$: Store){}

  logout(){
    this.store$.dispatch(authActions.beginLogout());
  }

  login(){
    this.store$.dispatch(authActions.beginLogin());
  }

  switchLanguage(language: string) {
    this.store$.dispatch(LanguageActions.setLanguage({language: language}));
  }
 }
