import { Entry, createClient } from "contentful";
import { environment } from "../../../environment";
import { Injectable } from "@angular/core";
import { TypeVuturia__news, TypeVuturia__newsSkeleton } from "./contentful-news";

@Injectable({providedIn: "root"})
export class NewsClient{
    
    private cdaClient = createClient({
        space: environment.contentful.spaceId,
        accessToken: environment.contentful.accessToken,
        environment: environment.contentful.environment
    });

    getAllNews(): Promise<ReadonlyArray<TypeVuturia__news>>{
        return this.cdaClient.getEntries<TypeVuturia__newsSkeleton>().then(res => res.items)
    }

}