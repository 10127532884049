import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store, StoreModule, provideState } from '@ngrx/store';
import { LanguageActions } from '../../state/language/language.actions';
import { selectNewsCollection } from '../../state/news/news.selectors';
import { provideEffects } from '@ngrx/effects';
import { NewsEffects } from '../../state/news/news.effects';
import { NewsActions } from '../../state/news/news.actions';
import { map, shareReplay } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    CommonModule, RouterModule
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent {

  news$ = this.store$.select(selectNewsCollection).pipe(map(x => x as any), shareReplay(1));

  constructor(private store$: Store){
  }
  switchLanguage(lang: string) {
    this.store$.dispatch(LanguageActions.setLanguage({language: lang}));
  }
}
