import { createReducer, on } from "@ngrx/store";
import { LanguageActions } from "./language.actions";
import { Language } from "../../domain/language";

export interface LanguageState{
    languageId: string;
    loading: boolean;
    languageCollection: ReadonlyArray<Language>
}

export const initialState: LanguageState = {
    languageId: 'en',
    loading: false,
    languageCollection: [
        {name: 'english', id: 'en'}
    ]
}

export const languageReducer = createReducer(
    initialState,
    on(LanguageActions.beginLoadLanguage, state => ({...state, loading: true})),
    on(LanguageActions.loadLanguagesSuccess, (state, {languageCollection}) => ({...state, loading: false, languageCollection: languageCollection})),
    on(LanguageActions.loadLanguagesError, (state, {error}) => ({...state, loading: false})),
    on(LanguageActions.setLanguage, (state, {language}) => ({...state, languageId: language}))
)