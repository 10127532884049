import { createActionGroup, props } from "@ngrx/store";
import { Device } from "../../domain/device";

export const playActions = createActionGroup({
    source: 'play',
    events: {
        'Load current device': props,
        'Set current device': props<{device?: Device}>(),
        'Play on device': props<{device: Device}>(),
        'Stop playing': props  
    }
});