import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { exhaustMap, of, switchMap } from "rxjs";
import { navbarActions, searchbarActions } from "./layout.actions";

@Injectable()
export class LayoutEffects{

    constructor(private actions$: Actions){

    }

    $closeSidbarOnNavigate = createEffect(() => this.actions$.pipe(
        ofType(routerNavigatedAction),
        switchMap(_ => [navbarActions.close(), searchbarActions.close()])
    ))

}