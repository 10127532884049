import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { UserReadmodel } from '../../domain/user/user-readmodel';

@Component({
  selector: 'app-profile-header',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule
  ],
  templateUrl: './profile-header.component.html',
  styleUrl: './profile-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileHeaderComponent { 

  addUserIcon = faUserPlus

  @Input() public user?: UserReadmodel;
  @Input() public showAddFriendButton = false;
  @Output() public onAddFriendButtonClicked = new EventEmitter();

  addFriend() {
    this.onAddFriendButtonClicked.next({});
  }


}
