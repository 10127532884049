import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatchPlayerReadmodel } from '../../../../../domain/user/user-readmodel';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-ffo-scoreboard',
  standalone: true,
  imports: [
    CommonModule, RouterModule
  ],
  templateUrl: './ffo-scoreboard.component.html',
  styleUrl: './ffo-scoreboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FfoScoreboardComponent {

  @Input() players: MatchPlayerReadmodel[] = [];

  getSortedPlayers = () => [...this.players].sort((a,b) => b.score - a.score);
}
