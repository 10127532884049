import { Observable, of } from "rxjs"
import { Language } from "../domain/language"
import { Injectable } from "@angular/core"

@Injectable({providedIn: "root"})
export class LanguageClient {
    getAll(): Observable<Language[]> {
        return of([
            { name: "Englisch", id: "en" },
            { name: "Deutsch", id: "de" }
        ])
    }
}