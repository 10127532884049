import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Friend } from "../../domain/user/friend";
import { environment } from "../../../environment";

@Injectable({
    providedIn: "root"
})
export class FriendClient{

    constructor(private http: HttpClient){

    }

    loadFriendsForMe(): Observable<Friend[]>{
        return this.http.get<FriendsResponse>(environment.vuturiaApi.baseUrl + '/api/user/me/friends').pipe(map(resp => resp.friends));
    }

    loadFriendsForUser(id: string): Observable<Friend[]>{
        return this.http.get<FriendsResponse>(environment.vuturiaApi.baseUrl + `/api/user/${id}/friends`).pipe(map(resp => resp.friends));
    }

    addFriend(id: string): Observable<boolean>{
        return this.http.post<AddFriendsResponse>(environment.vuturiaApi.baseUrl + '/api/user/me/friends', {friendId: id}).pipe(map(resp => resp.success));
    }

}

interface FriendsResponse{
    userId: string,
    friends: Friend[]
}

interface AddFriendsResponse{
    message: string,
    success: boolean
}