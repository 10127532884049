import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameSummaryComponent } from '../game-summary/game-summary.component';
import { GameHistoryComponent } from '../../game-history.component';
import { FfoScoreboardComponent } from '../ffo-scoreboard/ffo-scoreboard.component';
import { TeamScoreboardComponent } from '../team-scoreboard/team-scoreboard.component';
import { MatchReadmodel } from '../../../../../domain/user/user-readmodel';

@Component({
  selector: 'app-game-card',
  standalone: true,
  imports: [
    CommonModule, GameSummaryComponent, FfoScoreboardComponent, TeamScoreboardComponent
  ],
  templateUrl: './game-card.component.html',
  styleUrl: './game-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCardComponent {

  @Input() game?: MatchReadmodel;

  isExpanded: boolean = false;

  toggleExpand(){
    this.isExpanded = !this.isExpanded;
  }

}
