import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { friendSelectors } from '../../../state/user/user.selector';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-friends',
  standalone: true,
  imports: [
    CommonModule, RouterModule
  ],
  templateUrl: './friends.component.html',
  styleUrl: './friends.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FriendsComponent { 

  constructor(private store: Store){}

  friends$ = this.store.select(friendSelectors.selectFriends);

}
