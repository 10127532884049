import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faGamepad, faHome, faPerson, faUser } from '@fortawesome/free-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectSignedInProfile } from '../../state/authentication/authentication.selector';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { navigationActions } from '../../state/navigation/navigation.actions';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule
  ],
  template: `
  <div class="flex flex-row justify-around items-baseline text-2xl">
    <div class="p-4 hover:cursor-pointer text-center" (click)="navigateHome()" routerLinkActive="text-vuturiaRed xl:text-black"><fa-icon [icon]="homeIcon"/></div>
    <div class="p-4 hover:cursor-pointer text-center border rounded-full m-2 hover:scale-105 hover:animate-pulse" routerLink="/play" routerLinkActive="bg-vuturiaRed xl:text-black"><fa-icon [icon]="playIcon"/></div>
    <div class="p-4 hover:cursor-pointer text-center" (click)="navigateToUser()" routerLinkActive="text-vuturiaRed xl:text-black"><fa-icon [icon]="personIcon"/></div>
  </div>`,
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  homeIcon = faHome;
  playIcon = faGamepad;
  personIcon = faUser;

  userId$ = this.store.select(selectSignedInProfile).pipe(map(user => user?.id));

  constructor(private store: Store){

  }

  async navigateToUser(){
    const userId = await firstValueFrom(this.userId$);
    console.log(userId);
    if(userId){
      this.store.dispatch(navigationActions.navigateToUser({id: userId}));
    }else{
      this.store.dispatch(navigationActions.navigateToLogin())
    }
  }

  navigateHome(){
    this.store.dispatch(navigationActions.navigateHome());
  }
}
