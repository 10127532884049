import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { playActions } from "./play.actions";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { environment } from "../../../environment";
import { Device } from "../../domain/device";

@Injectable()
export class PlayEffects{

    constructor(private actions: Actions, private http: HttpClient){

    }

    playOnDevice$ = createEffect(() => 
        this.actions.pipe(
            ofType(playActions.playOnDevice),
            switchMap(action => 
                this.http.post<AssignPlayerResponse[]>(environment.vuturiaApi.baseUrl + `/api/v2/arcadecenter/${action.device.arcade}/device/${action.device.id}/player`, {mail: ''}).pipe(
                    map(resp => playActions.setCurrentDevice({device: {arcade: action.device.arcade, id: action.device.id}})),
                    catchError(_ => of(playActions.setCurrentDevice({device: undefined})))
                )
            )
        )
    )

    loadCurrentDevice$ = createEffect(() => this.actions.pipe(
        ofType(playActions.loadCurrentDevice),
        switchMap(action =>
            this.http.get<Device>(environment.vuturiaApi.baseUrl + '/api/v2/user/me/device').pipe(
                map(resp => {
                    var device: Device | undefined = undefined;
                    if(resp.id){
                        device = resp;
                    }
                    return playActions.setCurrentDevice({device: device})
                })
            )
        )
    ))

}

interface AssignPlayerResponse{
    deviceId: string,
    player: {
        name: string,
        id: string,
    },
    assignmentCreated: Date
}