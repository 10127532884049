import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "./user.reducer";
import { RootState } from "../root.state";
import { UserReadmodel } from "../../domain/user/user-readmodel";
import { UserProfile } from "../../domain/user/user-profile";

const selectUserFeature = createFeatureSelector<UserState>('user');

export const selectIsProfileLoading = createSelector(selectUserFeature, (userState) => userState.loading);
export const selectProfile= createSelector(selectUserFeature, (userState) => userState.profile);

export const selectGameHistory = createSelector(selectUserFeature, userState => userState.profile?.matchHistory ?? []);
export const selectUserStatistics = createSelector(selectUserFeature, userState => userState.profile?.userStatistics ?? null);

export const selectExpandedGameId = createSelector(selectUserFeature, userState => userState.expandedGame);

export const selectUserSearchInProgress = createSelector(selectUserFeature, userState => userState.userSarchInProgress);
export const selectUserSearchResult = createSelector(selectUserFeature, userState => userState.userSearchResult);

export const selectCurrentProfileBelongsToLoggedInUser = createSelector(
    (state: RootState) => state.user.profile, (state: RootState) => state.authentication.signedInUserProfile, (profile: UserReadmodel | undefined, signedIn: UserProfile | undefined) => profile?.id === signedIn?.id);

export const friendSelectors = {
    selectFriends: createSelector(
        selectUserFeature,
        (userState) => userState.friends
    ),
    currentUserIsFriend: createSelector(
        selectUserFeature,
        userState => !!userState.friends.find(f => f.userId === userState.profile?.id)
    )
}