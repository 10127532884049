<div *ngIf="user$ | async as user">
    <div class="flex flex-row justify-center items-center my-4" *ngIf="user != undefined">
        <div class="grow p-4">
            <h2 class="text-4xl font-teko font-semibold">{{user.userName}}</h2>
            <span class="text-gray-300 font-bold">"Early adopter"</span>
        </div>
        <div class="flex flex-col items-center hover:cursor-pointer" *ngIf="!(currentUserIsMe$ | async) && !(currentUserIsFriend$ | async)" (click)="addFriend()">
            <fa-icon class="text-2xl" [icon]="addUserIcon"></fa-icon>
            <span class="text-xs">Add Friend</span>
        </div>
    </div>

    <div class="flex flex-row justify-start gap-4 font-teko">
        <div routerLink="statistics" routerLinkActive="underline">Statistics</div>
        <div routerLink="games" routerLinkActive="underline">Games</div>
        <div routerLink="friends" routerLinkActive="underline" *ngIf="(currentUserIsMe$ | async) ?? false">Friends</div>
    </div>
    <router-outlet>

    </router-outlet>
</div>