import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LanguageClient } from "../../data/language.client";
import { LanguageActions } from "./language.actions";
import { EMPTY, catchError, exhaustMap, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { Store } from "@ngrx/store";
import { Language } from "../../domain/language";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LanguageEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private languageClient: LanguageClient,
    private translateService: TranslateService
  ) { }

  loadLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.beginLoadLanguage),
    exhaustMap(() => this.languageClient.getAll()
      .pipe(
        map(languages => LanguageActions.loadLanguagesSuccess({languageCollection: languages})),
        catchError((error) => of(LanguageActions.loadLanguagesError({error: error})))
      )  
    )
  ));

  selectUserLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.loadLanguagesSuccess),
    exhaustMap(({languageCollection}) => getUserLanguage(languageCollection)
      .pipe(
        map(userLang => LanguageActions.setLanguage({language: userLang.id})),
        catchError((error) => of(LanguageActions.loadLanguagesError({error: error})))
      )  
    )
  ));

  setUserLanguageInTranslateService$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.setLanguage),
    exhaustMap(({language}) => {
      storeUserLanguage(language);
      return this.translateService.use(language)
      }
    )), {dispatch: false});


  
}

function storeUserLanguage(language: string){
  window.localStorage.setItem('vuturia:lang', language);
}

function getUserLanguage(languageCollection: Language[]) {

  const preloadedLang = window.localStorage.getItem('vuturia:lang') ?? window.navigator.language;
  const language = languageCollection.find(lang => lang.id === preloadedLang);

  return of(language ?? languageCollection[0]);
}