import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NewsActions } from "./news.actions";
import { exhaustMap } from "rxjs";
import { NewsClient } from "../../data/news/news.client";

@Injectable()
export class NewsEffects {
  constructor(
    private actions$: Actions,
    private newsClient: NewsClient
  ) { }

  loadNews$ = createEffect(() => this.actions$.pipe(
    ofType(NewsActions.beginLoadNews),
    exhaustMap(() => this.newsClient.getAllNews().then(n => NewsActions.loadNewsFinished({news: n}))))
  );
}