<div *ngIf="userProfileLoading$ | async else registrationForm" class="flex justify-center items-center h-full">
    <fa-icon [icon]="spinnerIcon" animation="spin" size="2xl"></fa-icon>
</div>

<ng-template #registrationForm>
    <div>
        <h1 class="font-teko text-2xl text-center">Profil erstellen</h1>
        <form class="flex flex-col gap-5 justify-stretch" [formGroup]="form" (submit)="registerUser()">
            <div class="flex flex-col items-stretch">
                <label>Nutzername (max. 12 Zeichen):</label>
                <input formControlName="username" type="text" class="rounded-md text-black">
            </div>
            <div class="flex flex-col items-stretch">
                <label>eMail:</label>
                <input formControlName="mail" type="text" class="rounded-md text-black">
            </div>
            <div class="flex justify-between items-center gap-5">
                <label>Agb:</label>
                <input formControlName="agb" type="checkbox" class="text-black w-5 h-5 rounded-md">
            </div>
            <div class="flex justify-between items-center gap-5">
                <label>Marketing Mails:</label>
                <input formControlName="marketingConsent" type="checkbox" class="text-black w-5 h-5 rounded-md">
            </div>
            <button [disabled]="form.invalid" class="p-2 border-vuturiaRed3 border rounded-md hover:cursor-pointer hover:bg-vuturiaRed3 disabled:bg-gray-300 disabled:border-none disabled:hover:cursor-default" type="submit">Jetzt Registrieren!</button>
        </form>
    
        <div class="my-6 flex flex-col">
            <h2 class="font-teko text-lg text-center">Du hast schon einmal bei uns gespielt?</h2>
            <a class="hover:cursor-pointer text-center underline text-xs" routerLink="/login/link">Bestehenden Account verknüpfen</a>
        </div>
     </div>
</ng-template>
