import { createReducer, on } from "@ngrx/store";
import { navbarActions, searchbarActions } from "./layout.actions";

export interface LayoutState{
    sidebarExpanded: boolean,
    searchbarExpanded: boolean
};

const initialState: LayoutState = {
    sidebarExpanded: false,
    searchbarExpanded: false
}

export const layoutReducer = createReducer(
    initialState,
    on(navbarActions.close, (state) => ({...state, sidebarExpanded: false})),
    on(navbarActions.open, (state) => ({...state, sidebarExpanded: true})),
    on(navbarActions.toggle, (state) => ({...state, sidebarExpanded: !state.sidebarExpanded})),

    on(searchbarActions.close, state => ({...state, searchbarExpanded: false})),
    on(searchbarActions.open, state => ({...state, searchbarExpanded: true})),
    on(searchbarActions.toggle, state => ({...state, searchbarExpanded: !state.searchbarExpanded}))
);