import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeModule, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { Device } from '../../domain/device';
import { firstValueFrom } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCameraRotate } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-device-code-scanner',
  standalone: true,
  imports: [
    CommonModule,
    NgxScannerQrcodeModule,
    FontAwesomeModule
  ],
  templateUrl: './device-code-scanner.component.html',
  styleUrl: './device-code-scanner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCodeScannerComponent { 

  @ViewChild('action') scanner!: NgxScannerQrcodeComponent;

  @Output() onDeviceFound: EventEmitter<Device> = new EventEmitter<Device>();

  devices: any[] = [];

  switchCameraIcon = faCameraRotate;

  public config: ScannerQRCodeConfig = {
    isBeep: false,
    constraints: {
      video: {
        facingMode: {
          ideal: "environment"
        },
      }
    },
  }

  constructor(private http: HttpClient){}

  ngAfterViewInit(): void {
    this.scanner.start();
  }
  
  ngOnDestroy(): void {
    this.scanner.stop();
  }

  async cycleCamera(){
    const devices = await firstValueFrom(this.scanner.devices);
    if(devices.length == 0)
      return;

    const currentIndex = this.scanner.deviceIndexActive;
    if(currentIndex + 1 < devices.length){
      this.scanner.playDevice(devices[currentIndex + 1].deviceId)
    }else{
      this.scanner.playDevice(devices[0].deviceId)
    }
  }

  barcodeFound(results: ScannerQRCodeResult[]) {

    // https://beta.vuturia.de/play?deviceId=bielefeld;Player1
    console.log(results);
    if(!results[0])
      return;

    this.scanner.pause();
    const url = results[0]?.value;

    // const url = "https://beta.vuturia.de/play?deviceId=bielefeld;Player1";
    const regex = /deviceId=([^;]+;[^&]+)/;
    const match = url.match(regex);

    if (match) {
        const extractedValue = match[1];
        const splitted = extractedValue.split(';');
        const device: Device = {arcade: splitted[0], id: splitted[1]}
        this.onDeviceFound.next(device); 
    }else{
      this.scanner.play();
    }
  }
}
