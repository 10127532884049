import { createActionGroup, props } from "@ngrx/store";
import { Language } from "../../domain/language";

export const LanguageActions = createActionGroup({
    source: 'Language',
    events: {
        'Begin LoadLanguage': props,
        'Load Languages Success': props<{languageCollection: Language[]}>(),
        'Load Languages Error': props<{error: any}>(),
        'SetLanguage': props<{language: string}>()
    }
});