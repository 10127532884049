import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { friendActions, profileActions, userSearchActions } from "./user.actions";
import { EMPTY, catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environment";
import { Store } from "@ngrx/store";
import { UserReadmodel } from "../../domain/user/user-readmodel";
import { CollectionResponse, UserSearchResult } from "../../domain/user/user-searchresult";
import { Router } from "@angular/router";
import { navigationActions } from "../navigation/navigation.actions";
import { ToastrService } from "ngx-toastr";
import { FriendClient } from "../../data/friends/friend.client";
import { authActions } from "../authentication/authentication.actions";
import { ModalService } from "../../util/modal.service";
import { DeleteUserDialogComponent } from "../../components/delete-user-dialog/delete-user-dialog.component";
import { selectSignedInProfile } from "../authentication/authentication.selector";

@Injectable()
export class UserEffects{
    constructor(
        private actions$: Actions, 
        private http: HttpClient, 
        private store$: Store, 
        private router: Router, 
        private toastr: ToastrService, 
        private friendClient: FriendClient,
        private modalService: ModalService
    ){}


    loadProfileOnNavigation$ = createEffect(() => this.actions$.pipe(
        ofType(navigationActions.navigateToUser),
        map(action => profileActions.beginLoadUserProfile({id: action.id}))
    ));

    loadFriendsOnNavigation$ = createEffect(() => this.actions$.pipe(
        ofType(navigationActions.navigateToUser),
        map(action => friendActions.beginLoadFriends({id: action.id}))
    ));


    loadProfile$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.beginLoadUserProfile),
        switchMap(action => {
            return this.http.get<UserReadmodel>(environment.vuturiaApi.baseUrl + '/api/user/' + action.id + '/details')
            .pipe(
                map(res => profileActions.loadUserProfileSuccess({profile: res})),
                catchError(err => of(profileActions.loadUserProfileFailure({error: err})))
            );
        })
    ));

    searchUser$ = createEffect(() => this.actions$.pipe(
        ofType(userSearchActions.beginUserSearch),
        switchMap(action => this.http.get<CollectionResponse<UserSearchResult>>(environment.vuturiaApi.baseUrl + '/api/user?searchterm=' + action.searchTerm)
        .pipe(
            map(res => userSearchActions.userSearchSuccess({users: res.data})),
            catchError(err => of(userSearchActions.userSearchFailure({error: err})))
        ))
    ));

    addFriend$ = createEffect(() => this.actions$.pipe(
        ofType(friendActions.addFriend),
        switchMap(action => this.friendClient.addFriend(action.friendId)),
        tap(_ => this.toastr.success("Friend added")),
        map(_ => friendActions.addFriendSuccess())
    ));

    loadFriend$ = createEffect(() => this.actions$.pipe(
        ofType(friendActions.beginLoadFriends, authActions.loadUserProfileSuccess, friendActions.addFriendSuccess),
        switchMap(action => this.friendClient.loadFriendsForMe()),
        map(friends => friendActions.loadFriendsSuccess({friends})),
        catchError(error => of(friendActions.loadFriendsError({error})))
    ));

    showProfileDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.showDeleteUserProfileDialog),
        tap(() => {
            this.modalService.showModal(DeleteUserDialogComponent);
        })
    ), {dispatch: false})

    hideProfileDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.hideDeleteUserProfileDialog),
        tap(() => {
            this.modalService.closeModal();
        })
    ), {dispatch: false})

    deleteUserProfile$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.startDeleteUserProfile),
        withLatestFrom(this.store$.select(selectSignedInProfile)),
        switchMap(([action, profile]) => this.http.delete(environment.vuturiaApi.baseUrl + '/api/v2/user/' + profile?.id)),
        map(res => profileActions.deleteUserProfileSuccess()),
        catchError(err => of(profileActions.deleteUserProfileError({error: err})))
    ))

    userDeletedSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.deleteUserProfileSuccess),
        tap(_ => this.toastr.success("Success")),
        map(_ => (authActions.beginLogout()))
    ))

    userDeletedSuccessCloseDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.deleteUserProfileSuccess),
        map(_ => (profileActions.hideDeleteUserProfileDialog()))
    ))

    userDeletedError$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.deleteUserProfileError),
        tap(_ => this.toastr.error("Failed to delete Profile, please contact us via mail or phone!"))
    ), {dispatch: false})
}