import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatchPlayerReadmodel, MatchReadmodel } from '../../../../../domain/user/user-readmodel';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-team-scoreboard',
  standalone: true,
  imports: [
    CommonModule, RouterLink
  ],
  templateUrl: './team-scoreboard.component.html',
  styleUrl: './team-scoreboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamScoreboardComponent {

  @Input() teamNames: {id: number, name: string, score: number}[] = [];
  @Input() players: MatchPlayerReadmodel[] = [];

  getPlayersForTeam(teamId: number): any {
    return this.players.filter(p => p.teamId == teamId);
  } 

}
