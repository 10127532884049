import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NewsState } from "./news.reducer";
import { getRouterSelectors } from "@ngrx/router-store";

const fromNewsState = createFeatureSelector<NewsState>('news');
const routerSelectors = getRouterSelectors();

export const selectNewsCollection = createSelector(
    fromNewsState,
    (newsState) => newsState.newsCollection
);

export const selectCurrentDetailNews = createSelector(
    fromNewsState,
    routerSelectors.selectRouteParams,
    (state, {slug}) => {
        return state.newsCollection.find(n => n.fields['slug'] == slug)
    }
);