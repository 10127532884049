import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPersonCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { ProfileHeaderComponent } from '../../components/profile-header/profile-header.component';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { friendSelectors, selectCurrentProfileBelongsToLoggedInUser, selectIsProfileLoading, selectProfile } from '../../state/user/user.selector';
import { getRouterSelectors } from '@ngrx/router-store';
import { filter, firstValueFrom, lastValueFrom, map, tap, withLatestFrom } from 'rxjs';
import { friendActions, profileActions } from '../../state/user/user.actions';
import { RootState } from '../../state/root.state';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule, ProfileHeaderComponent, RouterModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent{

  addUserIcon = faPersonCirclePlus;
  user$ = this.store$.select(selectProfile);
  loading$ = this.store$.select(selectIsProfileLoading);
  currentUserIsMe$ = this.store$.select(selectCurrentProfileBelongsToLoggedInUser);
  currentUserIsFriend$ = this.store$.select(friendSelectors.currentUserIsFriend);

  constructor(private store$: Store<RootState>, private activatedRoute: ActivatedRoute){
    activatedRoute.paramMap.pipe(
      filter(params => params.has('id')),
      map(params => params.get('id')),
      filter(id => !!id))
    .subscribe(id =>{
      store$.dispatch(profileActions.beginLoadUserProfile({id: id!}))
    });
  }

  async addFriend(){
    console.log("loading user")
    var user = await firstValueFrom(this.user$);
    console.log(user);
    if(user)
      this.store$.dispatch(friendActions.addFriend({friendId: user.id!}));
  }
}
