<div *ngIf="!(isLoading$ | async) else loading" class="h-full">

    <div *ngIf="(currentDevice$ | async) as device else scanner" class="flex px-4 items-stretch justify-evenly flex-col h-full text-center">
        <div class="">
            <h1 class="text-xl">Du spielst auf: </h1>
            <div class="text-vuturiaGold text-4xl font-bold">{{device.id}}</div>
        </div>
        <div (click)="stopPlaying()" class="p-2 bg-vuturiaRed3 rounded-md"> Stop Playing </div>
    </div>

</div>

<ng-template #loading>
LOADING
</ng-template>

<ng-template #scanner>
    <div class="flex items-center justify-center basis-full lg:basis-2/3 xl:basis-1/2">
        <app-device-code-scanner (onDeviceFound)="startPlaying($event)"></app-device-code-scanner>
    </div>
</ng-template>