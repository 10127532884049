import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LanguageState } from "./language.reducer";

const fromLanguage = createFeatureSelector<LanguageState>('language');

export const selectCurrentLanguage = createSelector(
    fromLanguage,
    (state) => state.languageId
);

export const selectAvailableLanguages = createSelector(
    fromLanguage,
    (state) => state.languageCollection
)