import { createActionGroup, props } from "@ngrx/store";

export const navbarActions = createActionGroup({
    source: 'sidebar',
    events: {
        open: props,
        close: props,
        toggle: props
    }
});

export const searchbarActions = createActionGroup({
    source: 'searchbar',
    events: {
        open: props,
        close: props,
        toggle: props
    }
})
