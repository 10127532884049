<div class="flex gap-2 items-stretch justify-between h-15 p-4">
    <div class="w-5 z-20 flex justify-center items-center" (click)="toggleNavbar()">
        <fa-icon [icon]="burgerIcon"/>
    </div>
    <div class="grow flex items-center justify-stretch">
            <div class="w-full" [@inOutAnimation] *ngIf="!searchBarVisible" routerLink="/" style="background-image: url('/assets/logo.webp'); background-size: contain; background-repeat: no-repeat; background-position: center; height: 100%;">
            </div>
            <form class="w-full px-2" [formGroup]="headerForm" [@inOutAnimation] *ngIf="searchBarVisible">
                <input class="rounded-sm h-8 text-black w-full" type="text" formControlName="searchterm"/>
            </form>
    </div>

    <div class="text-2xl hover:cursor-pointer h-8" (click)="toggleSearchBar()">
        <fa-icon [icon]="searchIcon"/>
    </div>
</div>

<!--Search result-->
<ng-container *ngIf="searchBarVisible$ | async">
    <div class="text-center fixed w-full header-searchbar">
        <div *ngIf="userSearchInProgress$ | async">
            <fa-icon animation="spin" [icon]="loadingIcon"></fa-icon>
        </div>
        <div *ngIf="userSearchResult$ | async as result" class="w-full">
            <div *ngIf="result.length > 0" class="gap-2 grid bg-black p-4">
                <h2>Suchergebnis:</h2>
                <div *ngFor="let user of result" (click)="navigateToUser(user.id)" class="bg-vuturiaGray3 p-2 rounded-sm">
                    {{user.name}}
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!--  -->
