import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatchReadmodel } from '../../../../../domain/user/user-readmodel';

@Component({
  selector: 'app-game-summary',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './game-summary.component.html',
  styleUrl: './game-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameSummaryComponent {

  @Input() game?: MatchReadmodel

}
