import { GlobalPositionStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Type } from "@angular/compiler";
import { Component, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ModalService{


    overlayRef: OverlayRef | undefined;
    private position = this.overlay.position().global().centerHorizontally().centerVertically();
    
    constructor(private overlay: Overlay){}
    

    showModal<T>(component: ComponentType<T>){
        this.overlayRef = this.overlay.create({
            positionStrategy: this.position,
            hasBackdrop: true
        });
        const portal = new ComponentPortal(component)
        this.overlayRef.attach(portal);
        this.overlayRef.backdropClick().subscribe(ev => this.overlayRef?.detach())
    }

    closeModal(){
        this.overlayRef?.detach();
    }

}

