import { User } from "@auth0/auth0-angular";
import { createReducer, on } from "@ngrx/store";
import { UserProfile } from "../../domain/user/user-profile";
import { friendActions, profileActions, userSearchActions } from "./user.actions";
import { UserReadmodel } from "../../domain/user/user-readmodel";
import { UserSearchResult } from "../../domain/user/user-searchresult";
import { Friend } from "../../domain/user/friend";

export interface UserState {
    profile: UserReadmodel | undefined,
    expandedGame: string | undefined,
    loading: boolean,
    error: string,

    userSarchInProgress: boolean,
    userSearchterm: string,
    userSearchResult: UserSearchResult[]

    loadFriendsInProgress: boolean,
    addFriendInProgress: boolean,
    friends: ReadonlyArray<Friend>,

    deleteInProgress: boolean,
}

const initialState: UserState = {
    profile: undefined,
    expandedGame: undefined,
    loading: false,
    error: '',
    
    userSarchInProgress: false,
    userSearchterm: '',
    userSearchResult: [],

    loadFriendsInProgress: false,
    addFriendInProgress: false,
    friends: [],

    deleteInProgress: false
}

export const userReducer = createReducer(initialState,
    on(profileActions.beginLoadUserProfile, (state, action) => ({...state, loading: true})),
    on(profileActions.loadUserProfileSuccess, (state, action) => ({...state, loading: false, profile: action.profile})),
    on(profileActions.loadUserProfileFailure, (state, action) => ({...state, loading: false, error: action.error})),
    on(profileActions.expandGameDetails, (state, action) => ({...state, expandedGame: action.gameId})),
    on(profileActions.startDeleteUserProfile, (state, action) => ({...state, deleteInProgress: true})),
    on(profileActions.deleteUserProfileSuccess, state => ({...state, deleteInProgress: false})),
    on(profileActions.deleteUserProfileError, (state, action) => ({...state, deleteInProgress: false, error: action.error})),


    on(userSearchActions.beginUserSearch, (state, action) => ({...state, userSarchInProgress: true, userSearchterm: action.searchTerm})),
    on(userSearchActions.userSearchSuccess, (state, action) => ({...state, userSarchInProgress: false, userSearchResult: action.users})),
    on(userSearchActions.userSearchFailure, (state, action) => ({...state, userSarchInProgress: false, userSearchResult: [], error: action.error})),

    on(friendActions.beginLoadFriends, state => ({...state, loadFriendsInProgress: true})),
    on(friendActions.loadFriendsSuccess, (state, action) => ({...state, loadFriendsInProgress: false, friends: action.friends })),
    on(friendActions.loadFriendsError, (state, action) => ({...state, loadFriendsInProgress: false, friends: []})),
    on(friendActions.addFriend, state => ({...state, addFriendInProgress: true})),
    on(friendActions.addFriendSuccess, state => ({...state, addFriendInProgress: false}))
)