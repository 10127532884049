import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { selectUser } from '../../../state/authentication/authentication.selector';
import { authActions } from '../../../state/authentication/authentication.actions';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  constructor(private store$: Store, private router: Router){
    this.subscriptions.push(store$.select(selectUser).subscribe(user => {
      if(user){
        router.navigate(['']);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  login() {
    this.store$.dispatch(authActions.beginLogin());
  }

}
