import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { profileActions } from '../../state/user/user.actions';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-user-dialog',
  standalone: true,
  imports: [
    CommonModule, TranslateModule
  ],
  templateUrl: './delete-user-dialog.component.html',
  styleUrl: './delete-user-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteUserDialogComponent {

  constructor(private store: Store){}

  deleteUser(){
    this.store.dispatch(profileActions.startDeleteUserProfile());
  }

  hide(){
    this.store.dispatch(profileActions.hideDeleteUserProfileDialog());
  }

}
