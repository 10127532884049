import { User } from "@auth0/auth0-angular";
import { createActionGroup, props } from "@ngrx/store";
import { UserProfile } from "../../domain/user/user-profile";
import { HttpErrorResponse } from "@angular/common/http";

export const authActions = createActionGroup({
    source: 'user-auth',
    events: {
        'begin login': props,
        'login success': props<{user: User}>(),
        'login failed': props<{error: any}>(),

        'begin logout': props,
        'logout success': props,

        'load loginState': props,
        'set loginState': props<{user: User | undefined, profile:UserProfile| undefined}>(),
        
        'begin load userProfile': props,
        'load userProfile success': props<{profile: UserProfile}>(),
        'load userProfile failed': props<{error: HttpErrorResponse}>()
    }
});

export const registrationActions = createActionGroup({
    source: 'user-registration',
    events: {
        'begin registration': props<{body: UserRegistrationRequest}>(),
        'registration success': props<{profile: UserProfile}>(),
        'registration error': props<{error: any}>(),
    }
});

export interface UserRegistrationRequest{
    username: string,
    mail: string,
    agb: boolean,
    marketingConsent: boolean
}