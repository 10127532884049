import type { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'de.vuturia.app',
  appName: 'Vuturia',
  webDir: 'dist/capacitor/browser',
  ios:{
    handleApplicationNotifications: false
  },
  plugins: {
    LiveUpdates: {
      appId: '61d35611',
      channel: 'Production',
      autoUpdateMethod: 'background',
      maxVersions: 2
    }
  }
};

export default config;
