import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars, faDoorOpen, faRightToBracket, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { navbarActions, searchbarActions } from '../../state/layout/layout.actions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { animate, style, transition, trigger } from '@angular/animations';
import { BrowserModule } from '@angular/platform-browser';
import { debounceTime, filter, mergeMap, tap } from 'rxjs';
import { profileActions, userSearchActions } from '../../state/user/user.actions';
import { selectUserSearchInProgress, selectUserSearchResult } from '../../state/user/user.selector';
import { navigationActions } from '../../state/navigation/navigation.actions';
import { selectSearchbarExpanded } from '../../state/layout/layout.selectors';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule, TranslateModule, RouterModule, ReactiveFormsModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ width: 0, opacity: 1 }),
            animate('50ms',
              style({ width: '100%', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ width: '100%', opacity: 1 }),
            animate('50ms',
              style({ width: 0, opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class HeaderComponent {
  searchIcon = faSearch;
  loadingIcon = faSpinner;
  burgerIcon = faBars;

  searchBarVisible = false;
  searchBarVisible$ = this.store$.select(selectSearchbarExpanded).pipe(tap(sbe => this.searchBarVisible = sbe));
  headerForm = new FormGroup({
    searchterm: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  userSearchResult$ = this.store$.select(selectUserSearchResult);
  userSearchInProgress$ = this.store$.select(selectUserSearchInProgress);

  constructor(private store$: Store) {
    this.headerForm.valueChanges.pipe(filter(v => !!v.searchterm), debounceTime(1000), tap(v => console.log(v))).subscribe(v => store$.dispatch(userSearchActions.beginUserSearch({ searchTerm: v.searchterm! })))
  }

  toggleSearchBar() {
    this.store$.dispatch(searchbarActions.toggle());
  }



  toggleNavbar() {
    this.store$.dispatch(navbarActions.toggle());
  }


  navigateToUser(id: string) {
    this.store$.dispatch(navigationActions.navigateToUser({ id }))
  }

}
