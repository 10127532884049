import { Entry } from "contentful";
import { createFeature, createReducer, on } from "@ngrx/store";
import { NewsActions } from "./news.actions";
import { TypeVuturia__newsSkeleton } from "../../data/news/contentful-news";

export interface NewsState{
    newsCollection: ReadonlyArray<Entry<TypeVuturia__newsSkeleton>>,
    currentNewsSlug: string,
    loading: boolean
}

const initialState: NewsState = {
    newsCollection: [],
    currentNewsSlug: '',
    loading: false
}

export const newsReducer = createReducer(initialState,
    on(NewsActions.beginLoadNews, state => ({...state, loading: true})),
    on(NewsActions.loadNewsFinished, (state, {news}) => ({...state, newsCollection: news, loading: true})),
    on(NewsActions.loadNewsFailed, state => ({...state, loading: false})),
)