import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfile, selectUserStatistics } from '../../../state/user/user.selector';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [
    CommonModule, TranslateModule
  ],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsComponent { 

  statistics$ = this.store.select(selectUserStatistics);

  constructor(private store: Store){}

}
