import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAvailableLanguages, selectCurrentLanguage } from '../../state/language/language.selectors';
import { LanguageActions } from '../../state/language/language.actions';
import { TranslateModule } from '@ngx-translate/core';
import { profileActions } from '../../state/user/user.actions';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule, TranslateModule
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  constructor(private store: Store){

  }

  currentLanguage$ = this.store.select(selectCurrentLanguage);
  languagesAvailable$ = this.store.select(selectAvailableLanguages);

  switchLanguage(event: any){
    const lang = event.target.value as string;
    this.store.dispatch(LanguageActions.setLanguage({language: lang}));
  }

  deleteProfile(){
    this.store.dispatch(profileActions.showDeleteUserProfileDialog());
  }
}
