import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LayoutState } from "./layout.reducer";

const fromLayout = createFeatureSelector<LayoutState>('layout');

export const selectNavbarExpanded = createSelector(
    fromLayout,
    (state) => state.sidebarExpanded
);

export const selectSearchbarExpanded = createSelector(
    fromLayout,
    (state) => state.searchbarExpanded
)