import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeModule, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { DeviceCodeScannerComponent } from '../../components/device-code-scanner/device-code-scanner.component';
import { Device } from '../../domain/device';
import { Store } from '@ngrx/store';
import { playActions } from '../../state/play/play.actions';
import { selectCurrentDevice, selectPlayIsLoading } from '../../state/play/play.selectors';

@Component({
  selector: 'app-play',
  standalone: true,
  imports: [
    CommonModule, DeviceCodeScannerComponent
  ],
  templateUrl: './play.component.html',
  styleUrl: './play.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayComponent{

  isLoading$ = this.store.select(selectPlayIsLoading);
  currentDevice$ = this.store.select(selectCurrentDevice);

  constructor(private store: Store){}

  startPlaying(scanResult: Device){
    this.store.dispatch(playActions.playOnDevice({device: scanResult}));
  }

  stopPlaying() {
    this.store.dispatch(playActions.stopPlaying());
  }

}