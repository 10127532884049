<div class="text-sm mt-2">
    <div class="grid grid-cols-3 mt-2 bg-vuturiaGray rounded-sm p-1 px-2 font-bold">
        <span>Name</span>
        <span class="text-center">K/D</span>
        <span class="text-right">Score</span>
    </div>
    <div class="px-2 my-1 py-1 rounded-sm bg-vuturiaGray2" *ngFor="let player of getSortedPlayers()" [routerLink]="['/profile', player.id, 'games']">
        <div key={player.id} class="grid grid-cols-3">
            <span>{{player.name}}</span>
            <span class="text-center">{{(player.kills / (player.deaths != 0 ? player.deaths : 1)).toFixed(2)}}</span>
            <span class="text-right">Score: {{player.score}}</span>
        </div>
    </div>
</div>