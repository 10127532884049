<div class="flex flex-row flex-wrap justify-center gap-6">
    <div *ngFor="let newsEntry of news$ | async" class="basis-96">
        <div class="rounded-md overflow-hidden bg-vuturiaGray1 h-full flex flex-col">
            <img src="https://{{newsEntry.fields.header?.fields.file.url}}?fm=jpg&fl=progressive&q=50&w=1000"/>
            <div class="m-2 grow">
                <h2 class="text-3xl font-semibold text-vuturiaPurple3 font-teko">{{newsEntry.fields.title}}</h2>
                <p class="prose-sm leading-snug prose-invert text-white font-light">{{newsEntry.fields.abstract}}</p>
            </div>
            <div class="text-sm bg-vuturiaRed p-2" [routerLink]="['/news',newsEntry.fields.slug]">
                Weiter lesen ...
            </div>
        </div>
    </div>
</div>
