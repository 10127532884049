import { Component, NgZone, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { App } from '@capacitor/app';

import OneSignalNative from 'onesignal-cordova-plugin';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, mergeMap, of } from 'rxjs';
import { Browser } from '@capacitor/browser';
import config from '../../capacitor.config';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environment';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { OneSignal } from 'onesignal-ngx';
import { Capacitor } from '@capacitor/core';

const callbackUri = Capacitor.getPlatform() != 'web' ? `${config.appId}://vuturia.eu.auth0.com/capacitor/${config.appId}/callback` : window.location.origin;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule, TranslateModule, FooterComponent, HeaderComponent, SidenavComponent, FontAwesomeModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Vuturia';
  constructor(private store$: Store, private auth: AuthService, private ngZone: NgZone, private oneSignal: OneSignal, private router: Router) {

    console.log(Capacitor.getPlatform());
    setupOnesignal(oneSignal);
  }

  ngOnInit(): void {
    this.initializeApp();
  }

  private initializeApp() {
    // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
    App.addListener('appUrlOpen', ({ url }) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        if (url?.startsWith(callbackUri)) {
          this.handleLoginCallback(url);
        }

        if(url?.startsWith(environment.applicationUrl)){
          this.handleDeepLink(url);
        }
      });
    });
  }

  private handleDeepLink(url: string){
    const slug = url.split(environment.applicationUrl).pop();
    if (slug) {
        this.router.navigateByUrl(slug);
    }
  }

  private handleLoginCallback(url: string) {
    if (
      url.includes('state=') &&
      (url.includes('error=') || url.includes('code='))
    ) {
      this.auth
        .handleRedirectCallback(url)
        .pipe(mergeMap(_ => Browser.close()), catchError((err, cought) => {
          Browser.close();
          return of(false);
        }))
        .subscribe();
    } else {
      Browser.close();
    }
  }
}

async function setupOnesignal(oneSignal: OneSignal) {
  console.log(oneSignal);
  /// @ts-ignore
  if (!window.cordova) {
    await oneSignal.init({
      appId: environment.oneSignalId
    });
  } else {
    OneSignalNative.initialize(environment.oneSignalId);
  }
}
