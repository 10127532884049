import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserCardScannerComponent } from '../../../components/user-card-scanner/user-card-scanner.component';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [
    CommonModule, UserCardScannerComponent
  ],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent { }
